import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
class DocIndex extends React.Component {
  render() {
    return (
      <Layout />
    )
  }
}

export default DocIndex

export const pageQuery = graphql`
  query IndexQuery {
    allOpenApiSpec {
      edges {
        node {
          name
          title
        }
      }
    }
  }
`
